import { data } from 'src/app/Components/data-table/DataTableOptions';
import { GridDataModel } from '../../modus-select/modus-select.component';
import { ProjectDocumentManagerState } from './project-document-manager.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { formatFileSize } from 'src/app/@core/utils';
import { ChangeLogRow } from 'src/app/modules/protocol-nerp/models';

export interface ProjectFileRow extends data {
    name: string;
    fileName: string;
    size: string;
    lastUpdated: string;
    id: string;
    status: string;
}

export const selectDocumentManagerState =
    createFeatureSelector<ProjectDocumentManagerState>('projectDocumentManager');

export const selectIsWorking = createSelector(
    selectDocumentManagerState,
    (state) => state.isWorking
);

export const selectIsPending = createSelector(
    selectDocumentManagerState,
    (state) => state.isWorking
);


export const selectProtocolFileUploadDialogOpen = createSelector(
    selectDocumentManagerState,
    (state) => state.fileUploadDialogOpen
);


export const selectProjectDocumentList = createSelector(
    selectDocumentManagerState,
    (state) => state.ProjectDocumentList
);


export const getProjectDocumentGrid = createSelector(
    selectProjectDocumentList,
    (projectDocuments): GridDataModel<ProjectFileRow> => {
        if (!projectDocuments || !projectDocuments.projectFiles) {
            return {
                data: [],
                count: 0
            };
        }

        const list = projectDocuments
            .projectFiles.map(e => {
                const fileName = e.fileName.split('\\')[2];
                return {
                    id: e.id,
                    blobIdentifier: e.blobIdentifier,
                    name: e.name,
                    fileName: fileName,
                    size: formatFileSize(e.size),
                    _id: e.id,
                    _selected: false,
                    active: true,
                    lastUpdated: e.lastUpdated,
                    status: e.status
                };
            }) || [];
        return {
            data: list,
            count: projectDocuments.projectFiles__count
        }
    }
)

export const selectDocumentSelected = createSelector(
    selectDocumentManagerState,
    (state) => state.selectedFile
);

export const selectCurrentProject = createSelector(
    selectDocumentManagerState,
    (state) => state.selectedProjectId
);



/*

Upload Document Modal

*/

export const selectDocumentUploadIsWaiting = createSelector(
    selectDocumentManagerState,
    (state) => state.isDocumentUploadWorking
)

export const selectEditDocumentDialogOpen = createSelector(
    selectDocumentManagerState,
    (state) => state.editDocumentDialogOpen
);

export const selectIsEditDocumentWorking = createSelector(
    selectDocumentManagerState,
    (state) => state.isEditDocumentWorking
)

export const selectProjectChangeLogModalDialogState = createSelector(
    selectDocumentManagerState,
    (state) => state.changeLogModalDialogOpen
);

export const selectChangeLogModalDocumentId = createSelector(
    selectDocumentManagerState,
    (state) => state.changeLogSelectedProjectFileId
);

export const selectChangeLogList = createSelector(
    selectDocumentManagerState,
    (state) => state.changeLogGridData
);


export const selectChangeLogGrid = createSelector(
    selectChangeLogList,
    (state): GridDataModel<ChangeLogRow> => {
        if (!state) {
            return {
                data: [],
                count: 0
            };
        }
        const list: ChangeLogRow[] = state.projectFileLogs.map(e => {

            const fileName = e.blobIdentifier?.split('\\')[2];
            return {
                id: e.id,
                time: e.time,
                change: e.change,
                user: e.user,
                fileName: fileName,
                blobIdentifier: e.blobIdentifier ?? '',
                _id: e.id,
                _selected: false,
            };
        }) || [];

        return {
            data: list,
            count: state.projectFileLogs__count
        }
    }
)


