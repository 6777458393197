import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CommonModule, DOCUMENT, isPlatformBrowser} from '@angular/common';
import {CUSTOM_ELEMENTS_SCHEMA, InjectionToken, ModuleWithProviders, NgModule, PLATFORM_ID} from '@angular/core';
import {NavBarMenuComponent} from '../Components/nav-bar-menu/nav-bar-menu.component';
import {NotificationsComponent} from '../Components/notifications/notifications.component';


import {AccessRequestComponent} from '../Components/access-request/access-request.component';
import {CounterComponent} from '../Components/counter/counter.component';
import { CounterTotalComponent } from '../Components/counter-total/counter-total.component';
import {DataTableComponent} from '../Components/data-table/data-table.component';
import {DateTimepickerComponent} from '../Components/date-time-picker/date-time-picker.component';
import {ConfirmationModalComponent} from '../Components/confirmation-modal/confirmation-modal.component';

import {provideHttpClient, withInterceptors, withInterceptorsFromDi} from '@angular/common/http';
import {AngularSvgIconModule} from 'angular-svg-icon';
import {IconsRegistryService} from './services/iconsRegistry.service';
import {CmsGraphQLModule} from '../cms-graphql.module';
import {ContentService} from './services/content.service';
import {HtmlContentComponent} from '../Components/html-content/html-content.component';
import {SidePanelComponent} from '../Components/side-panel/side-panel.component';
import {FilterComponent} from '../Components/filter/filter.component';
import {ChipSelectComponent} from '../Components/chip-select/chip-select.component';
import {FloatModalComponent} from '../Components/float-modal/float-modal.component';
import {WaitSpinnerComponent} from '../Components/wait-spinner/wait-spinner.component';
import {ModusInputComponent} from './components/modus-input/modus-input.component';
import {ModusTextInputDirective} from './directives/modus-input.directive';
import {ModusSelectDirective} from './directives/modus-select.directive';
import {ModusSelectComponent} from './components/modus-select/modus-select.component';
import {CcxGridComponent} from './components/ccx-grid/ccx-grid.component';
import {deleteButtonCellEditor} from './components/ccx-ag-datagrid/cellEditor/delete-button-cell-editor.component';
import {dotsCellRender} from './components/ccx-ag-datagrid/cellEditor/dots-cell-editor.component';
import {dotsMenuCellEditor} from './components/ccx-ag-datagrid/cellEditor/dots-menu-cell-editor.component';

import {NumericCellEditor} from './components/ccx-ag-datagrid/cellEditor/numeric-cell-editor.component';
import {LinkCellEditor} from './components/ccx-ag-datagrid/cellEditor/link-cell-editor.component';
import {LinkToProjectCellEditor} from './components/ccx-ag-datagrid/cellEditor/link-to-project-cell-editor.component';
import {AlphanumericCellEditor} from './components/ccx-ag-datagrid/cellEditor/alphanumeric-cell-editor.component';
import {CcxGridEditingComponent} from './components/ccx-grid-editing.component/ccx-grid-editing.component';

import {TranslateTemplatePipe} from './pipes/translate-template.pipe';
import {CcxPagerComponent} from './components/ccx-pager/ccx-pager.component';
import {LocalizedDatePipe} from './pipes/locale-datetime-root.pipe';
import {ProjectStatusComponent} from '../Components/project-status/project-status.component';
import {CcxButtonComponent} from './components/ccx-button/ccx-button.component';
import {CcxDataTable} from './components/ccx-dataTable/ccx-data-table.component';

import {MatInputModule} from '@angular/material/input'
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatNativeDateModule} from '@angular/material/core';
import {SafeHtmlPipe} from './pipes/safe-html.pipe';
import {CcxFileUploadComponent} from './components/ccx-file-upload/ccx-file-upload.component';
import {NgMultiSelectDropDownModule} from './components/multi-select-dropdown-ext';
import {
    CcxUtilityPanelComponent,
    CcxUtilityPanelTemplateMarkerDirective
} from './components/ccx-utility-panel/ccx-utility-panel.component';
import {CcxFilterDataSourceComponent} from './components/ccx-filter-datasource/ccx-filter-data-source.component';
import {CcxGridFilterPanelComponent} from '../Components/ccx-grid-filter-panel/ccx-grid-filter-panel.component';
import {DictionaryService} from '../transloco-root.module';
import {CcxBreadcrumbComponent} from './components/ccx-breadcrumb/ccx-breadcrumb.component';
import {RouterLink} from '@angular/router';
import {CcxDialogContainer, DialogService} from './components/dialog/dialog.service';
import {DialogModule} from '@angular/cdk/dialog';
import {AgGridModule} from 'ag-grid-angular';

import {CcxExpansionPanel} from '../Components/expansion-panel/ccx-expansion-panel.component';
import {MomentModule} from 'ngx-moment';
import {CcxSelectComponent} from './components/ccx-select/ccx-select.component';

import {CcxAgGridComponent} from './components/ccx-ag-grid/ccx-ag-grid.component';
import {
    CcxTableComponent
} from './components/ccx-table/ccx-table.component';
import {
    CcxTableCellRendererComponent, CcxTableNoRowsOverlayComponent
} from './components/ccx-table/ccx-table-cell-renderer.component';
import {CcxTableTextEditorCellRenderer} from './components/ccx-table/ccx-table-text-editor-cell-renderer.component';
import {acceptButtonCellEditor} from './components/ccx-ag-datagrid/cellEditor/accept-button-cell-editor.component';
import {documentStatusCellEditor} from './components/ccx-ag-datagrid/cellEditor/document-status-cell-editor.component';
import {invitedBadgeCellEditor} from './components/ccx-ag-datagrid/cellEditor/invited-badge-cell-editor.component';
import {PowerBIEmbedModule} from 'powerbi-client-angular';
import {PowerBiEmbedTokenService} from '../@auth/services/powerBiEmbedToken.service';
import {PageNavigationService} from './services/page-navigation.service';
import {NoContentAvailableComponent} from '../Components/no-content-available/no-content-available.component';
import {CcxConfirmationDialogComponent} from './components/ccx-confirmation-dialog/ccx-confirmation-dialog.component';
import {
    KebabMenuCellRendererComponent
} from './components/ccx-ag-datagrid/cellEditor/kebab-menu-cell-renderer.component';
import {KebabMenuCellEditorComponent} from './components/ccx-ag-datagrid/cellEditor/kebab-menu-cell-editor.component';
import {ClickOutDirective} from './directives/clickedOut.directive';
import {
    CoefficientsEditorShellComponent
} from '../modules/protocols/coefficients-editor/coefficients-editor-shell.component';
import {CcxSelectCellRendererComponent} from './components/ccx-table/ccx-select-cell-renderer.component';
import {CcxTableButtonCellRendererComponent} from './components/ccx-table/ccx-table-button-cell-renderer.component';
import {CcxFileBrowserComponent} from './components/ccx-file-browser/ccx-file-browser.component';
import {CdkConnectedOverlay} from '@angular/cdk/overlay';
import {CcxOverlayService} from './services/overlay.service';
import {LayoutService} from './services/layout.service';
import { CcxCalendarHeader } from '../Components/date-time-picker/date-time-picker-header/date-time-picker-header.component';
import {ClickedInsideElementDirective} from './directives/clicked-inside-element.directive';
import { ProtocolOrganizationProfileComponent } from '../modules/protocols/organization/protocol-organization-profile/protocol-organization-profile.component';
import { DocumentManagerShellComponent } from './components/document-manager-shell/document-manager.shell.component';
import { UploadFileShellComponent } from './components/document-manager-shell/upload-file-shell/upload-file.shell.component';
import { ChangeLogCellEditor } from './components/ccx-ag-datagrid/cellEditor/change-log-link-cell-editor.component';
import { ChangeLogModalComponent } from './components/document-manager-shell/change-log-modal/change-log-modal.component';
import { LinkToProjectShellComponent } from './components/document-manager-shell/link-to-project-shell/link-to-project-shell.component';
import { EditDocumentShellComponent } from './components/document-manager-shell/edit-document-shell/edit-document-shell.component';
import {TranslocoModule} from '@jsverse/transloco';
import { DocumentService } from './services/document.service';
import {ModusAngularComponentsModule} from '@trimble-oss/modus-angular-components';
import {AuthInterceptor} from '../@auth/auth-interceptor';
import {
    DocumentManagerDataOceanComponent
} from './components/document-manager-shell/document-manager-beef/document-manager-data-ocean.component';
import { CropZoneLevelRadioRendererComponent } from '../modules/protocol-nerp/organization/cropzone-radio-renderer.component';
import { ProjectDocumentManagerShellComponent } from './components/project-document-manager-shell/project-document-manager.shell.component';
import { ProjectChangeLogModalComponent } from './components/project-document-manager-shell/change-log-modal/change-log-modal.component';
import { ProjectEditDocumentShellComponent } from './components/project-document-manager-shell/project-edit-document-shell/edit-document-shell.component';
import { ProjectUploadFileShellComponent } from './components/project-document-manager-shell/project-upload-file-shell/upload-file.shell.component';
const BASE_MODULES = [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslocoModule,
    ModusAngularComponentsModule,
    AngularSvgIconModule,
    CmsGraphQLModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    NgMultiSelectDropDownModule,
    DialogModule,
    MomentModule,
    AgGridModule,
    PowerBIEmbedModule,
];
const PIPES: any[] = [
    TranslateTemplatePipe,
    LocalizedDatePipe,
    SafeHtmlPipe
];
const COMPONENTS: any[] = [NavBarMenuComponent,
    NotificationsComponent,
    AccessRequestComponent,
    CounterComponent,
    CounterTotalComponent,
    DataTableComponent,
    HtmlContentComponent,
    ConfirmationModalComponent,
    SidePanelComponent,
    FilterComponent,
    ChipSelectComponent,
    FloatModalComponent,
    WaitSpinnerComponent,
    ModusInputComponent,
    ModusTextInputDirective,
    ModusSelectComponent,
    ModusSelectDirective,
    CcxGridComponent,
    CcxSelectComponent,
    deleteButtonCellEditor,
    dotsCellRender,
    dotsMenuCellEditor,
    acceptButtonCellEditor,
    documentStatusCellEditor,
    KebabMenuCellRendererComponent,
    KebabMenuCellEditorComponent,
    NumericCellEditor,
    AlphanumericCellEditor,
    LinkCellEditor,
    LinkToProjectCellEditor,
    invitedBadgeCellEditor,
    CcxGridEditingComponent,
    CcxPagerComponent,
    ProjectStatusComponent,
    CcxButtonComponent,
    DateTimepickerComponent,
    CcxExpansionPanel,
    CcxDataTable,
    CcxFileUploadComponent,
    CcxUtilityPanelComponent,
    CcxFilterDataSourceComponent,
    CcxUtilityPanelTemplateMarkerDirective,
    CcxGridFilterPanelComponent,
    CcxBreadcrumbComponent,
    CcxDialogContainer,
    CcxAgGridComponent,
    CcxTableComponent,
    CcxTableTextEditorCellRenderer,
    CcxTableCellRendererComponent,
    CcxTableButtonCellRendererComponent,
    CcxTableNoRowsOverlayComponent,
    CcxSelectCellRendererComponent,
    NoContentAvailableComponent,
    CcxConfirmationDialogComponent,
    ClickOutDirective,
    ClickedInsideElementDirective,
    CoefficientsEditorShellComponent,
    CcxFileBrowserComponent,
    DocumentManagerShellComponent,
    ProjectDocumentManagerShellComponent,
    DocumentManagerDataOceanComponent,
    UploadFileShellComponent,
    ProjectUploadFileShellComponent,
    CcxCalendarHeader,
    ProtocolOrganizationProfileComponent,
    ChangeLogCellEditor,
    ChangeLogModalComponent,
    ProjectChangeLogModalComponent,
    LinkToProjectShellComponent,
    EditDocumentShellComponent,
    ProjectEditDocumentShellComponent,
    CropZoneLevelRadioRendererComponent
];

export function windowFactory(platformId: Object): Window | undefined {
    if (isPlatformBrowser(platformId)) {
        return window;
    }
    return undefined;
}

export const WINDOW_REF = new InjectionToken<Window>('Window');
export const DOCUMENT_REF = new InjectionToken<Document>('Document');

const SHARED_PROVIDERS: any[] = [
    IconsRegistryService,
    DocumentService,
    ContentService,
    DictionaryService,
    DialogService,
    PowerBiEmbedTokenService,
    PageNavigationService,
    CcxOverlayService,
    LayoutService,
    provideHttpClient(withInterceptors([AuthInterceptor])),
    {provide: DOCUMENT_REF, useExisting: DOCUMENT},
    {provide: WINDOW_REF, useFactory: windowFactory, deps: [PLATFORM_ID]},
];

@NgModule({
    imports: [...BASE_MODULES, RouterLink, CdkConnectedOverlay],
    exports: [...BASE_MODULES, COMPONENTS, PIPES],
    declarations: [...COMPONENTS, ...PIPES],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],

})
export class SharedModule {
    static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
            providers: [...SHARED_PROVIDERS]
        }
    }
}
