<div class="">
    <app-spinner [isWorking]="isWorking()"></app-spinner>
    <div class="">
        <ccx-grid-filter-panel [counter]="filterCounter" (onGridSearchBy)="gridSearchBy($event)"
            (onToggleFilter)="filterToggled()">
            <ng-content select="[filter-panel]"></ng-content>
        </ccx-grid-filter-panel>

        <ccx-grid [overlayLoadingTemplate]="'<span></span>'" [overlayNoRowsTemplate]="'<span></span>'"
            [dataSource]="ProjectDocumentDataSource" [rowSelection]="'multiple'" [gridOptions]="gridOptions"
            (selectionChanged)="onSelectionChanged($event)" (modelUpdated)="onModelUpdated($event)"
            [suppressMovableColumns]="true" [suppressCellFocus]="true" (onCellClick)="onCellLinkClick($event)">
        </ccx-grid>

        <ng-container *ngIf="(ProjectDocumentDataSource?.dataResponse$ | async) as dataModel">
            <div *ngIf="dataModel.data.length == 0" class="no-rows-overlay__wrapper">
                <div class="no-rows-overlay__content">
                    <svg-icon [svgClass]="'no-rows-overlay-svg-icon'" name="file"></svg-icon>
                    <span class="no-rows-overlay__text">{{'No document found' | transloco}}</span>
                    <ccx-button  width="7.5em" height="2.5625rem" size="medium"
                        buttonStyle="outline" color="secondary">

                        <svg-icon class="main-view-add-icon-svg" name="upload-arrow">
                        </svg-icon>{{'Upload'| transloco}}
                    </ccx-button>
                </div>
            </div>
        </ng-container>
    </div>
</div>
