import { createAction, props } from '@ngrx/store';
import {
    DeleteDocumentInput,
    EditDocumentInput,
    GetDocumentChangeLogsQuery,
    ProjectDocumentsQueryVariables,
    ProtocolsDocumentDefinitionQuery,
    ProtocolsDocumentDefinitionQueryVariables,
    SubmitDocumentInput,
    VerifyDocumentInput,
    ProjectDocumentsQuery,
    SubmitProjectFileInput,
    SubmitProjectFileMutation,
    ProjectFilesQuery,
    EditProjectFileInput,
    ProjectFileLogsQuery,
    ProjectFileLogsQueryVariables
} from 'src/app/@core/generated/operations-core-graphql';
import { ApolloError } from '@apollo/client/core';

export const protocolProjectDocumentLoad = createAction('[Project Document Manager] Grid Load',
    props<{ input: ProjectDocumentsQueryVariables }>());

export const protocolProjectDocumentSucess = createAction('[Project Document Manager] Grid Success',
    props<{ list: ProjectFilesQuery }>());

export const protocolProjectDocumentFail = createAction('[Project Document Manager] Grid Fail',
    props<{ errors: any }>());


export const protocolProjectFileUploadDialogOpen = createAction('[Project Document Manager File Upload] Add Dialog Open',
    props<{ value: boolean }>());

export const protocolSubmitFilesUploadedLoad = createAction('[Project Document Manager submit Files Upload] Submit Files uploaded',
    props<{ input: SubmitProjectFileInput }>());

export const protocolSubmitFilesUploadedSuccess = createAction('[Project Document Manager submit Files Upload] Submit Files uploaded Success',
    props<{ result: any }>());

export const protocolSubmitFilesUploadedFail = createAction('[Project Document Manager submit Files Upload] Submit Files uploaded Fail',
    props<{ error: ApolloError }>());

export const protocolClearFileUpload = createAction('[Project Document Manager submit Files Upload] Clear  new File Uploaded',
    props<{ clear: boolean }>());

export const protocolUpdateFileUpload = createAction('[Project Document Manager submit Files Upload] Update new File Uploaded',
    props<{ input: { id: '', iconType: string, name: string, nameFile: string, category: string, statusbar: number } }>());

export const protocolDeleteFileUpload = createAction('[Project Document Manager submit Files Upload] Delete File Uploaded',
    props<{ id: string }>());

//Edit document

export const protocolEditDocumentDialogOpen = createAction('[Project Document Manager File Edit] Add Dialog Open',
    props<{ value: boolean }>());

export const protocolEditDocumentLoad = createAction('[Project Document Manager] Edit Document Load',
    props<{ input: EditProjectFileInput }>());

export const protocolEditDocumentSuccess = createAction('[Project Document Manager] Edit Document Success',
    props<{ result: any }>());

export const protocolEditDocumentFail = createAction('[Project Document Manager] Edit Document Fail',
    props<{ errors: ApolloError }>());


export const projectFileLoad = createAction('[Project Document Manager] Get Document Load',
    props<{ input: { documentId: string } }>());

export const protocolDocumentSuccess = createAction('[Project Document Manager] Get Document Success',
    props<{ document: any }>());

export const protocolDocumentFail = createAction('[Project Document Manager] Get Document Fail',
    props<{ errors: any }>());



export const documentChangeLogsLoad = createAction('[Project Document Manager] Get Document Change Logs Load',
    props<{ input: ProjectFileLogsQueryVariables }>());

export const documentChangeLogsSuccess = createAction('[Project Document Manager] Get Document Change Logs Success',
    props<{ data: ProjectFileLogsQuery }>());

export const documentChangeLogsFail = createAction('[Project Document Manager] Get Document Change Logs Fail',
    props<{ errors: any }>());

export const projectDocumentChangeLogsDialogOpen = createAction('[Project Document Manager] Document Change Logs Dialog Open',
    props<{ value: boolean, documentId: string | null }>());

export const cleanDocumentManagerState = createAction('[Project Document Manager] Clean State');

/*
 *   Documents Deletion
*/

export const deleteDocumentLoadAction = createAction('[Project Document Manager] Delete Document Loaded',
    props<{ input: DeleteDocumentInput }>());

export const deleteDocumentSuccessAction = createAction('[Project Document Manager] Delete Document Success',
    props<{ data: ProjectDocumentsQueryVariables }>());

export const deleteDocumentFailAction = createAction('[Project Document Manager] Delete Document Fail',
    props<{ errors: any }>());



export const clearProjectDocumentManagerState = createAction('[Project Document Manager Organization] Clear');