import { createAction, props } from "@ngrx/store";
import {
    AddOrganizationProjectInput,
    GetCropZoneDetailsQuery,
    GetCropZoneDetailsQueryVariables,
    GetCropZoneTableQuery,
    GetCropZoneTableQueryVariables,
    GetCropzoneYearsQuery,
    GetCropzoneYearsQueryVariables,
    GetNerpOrganizationDashboardDataQuery,
    GetNerpOrganizationDashboardDataQueryVariables,
    PutCropZoneLevelInput,
    UpdateCropZoneLevelMutationVariables
} from '../../../@core/generated/operations-protocol-nerp-graphql';
import { DeleteProjectInput } from "src/app/@core/generated/operations-protocol-beef-graphql";
import { NerpProjectRow } from "..";
import { SelectedProjectItem } from "src/app/@core/components/document-manager-shell/document-manager.shell.component";

export const protocolNerpProjectsLoad = createAction('[Nerp Projects] Projects Grid Load',
    props<{ input: any }>());

export const protocolNerpProjectsSucess = createAction('[Nerp Projects] Projects Grid Success',
    props<{ list: any }>());

export const protocolNerpProjectsFail = createAction('[Nerp Projects] Projects Grid Fail',
    props<{ errors: any }>());

export const projectNamesFilterLoad = createAction('[Nerp Organization Details] Project Name Filter Load',
    props<{ input: any }>());

export const projectNamesFilterLoadSucess = createAction('[Nerp Organization Details] Project Name Filter Load Success',
    props<{ list: any }>());

export const projectNamesFilterLoadFail = createAction('[Nerp Organization Details] Project Name Filter Load Fail',
    props<{ errors: any }>());


export const setIsWorking = createAction(' [Nerp] is working',
    props<{ input: boolean }>());

export const protocolNerpOrganizationDashboardDataLoad = createAction('[Nerp Organization Dashboard Data] Load',
    props<{ input: GetNerpOrganizationDashboardDataQueryVariables }>());

export const protocolNerpOrganizationDashboardDataSuccess = createAction('[Nerp Organization Dashboard Data] Success',
    props<{ result: GetNerpOrganizationDashboardDataQuery }>());

export const protocolNerpOrganizationDashboardDataFail = createAction('[Nerp Organization Dashboard Data] Fail',
    props<{ errors: any }>());

export const protocolNerpCropZonesLoad = createAction('[Nerp Organization Crop Zones] Load',
    props<{ input: GetCropZoneTableQueryVariables }>());

export const protocolNerpCropZonesSuccess = createAction('[Nerp Organization Crop Zones] Success',
    props<{ result: GetCropZoneTableQuery }>());

export const protocolNerpCropZonesFail = createAction('[Nerp Organization Crop Zones] Fail',
    props<{ errors: any }>());

export const protocolNerpCropZoneDetailLoad = createAction('[Nerp Organization Crop Zone Detail] Load',
    props<{ input: GetCropZoneDetailsQueryVariables }>());

export const protocolNerpCropZoneDetailSuccess = createAction('[Nerp Organization Crop Zone Detail] Success',
    props<{ result: GetCropZoneDetailsQuery }>());

export const protocolNerpCropZoneDetailFail = createAction('[Nerp Organization Crop Zone Detail] Fail',
    props<{ errors: any }>());

export const protocolCropZoneDialogOpen = createAction('[Document Manager] Crop Zone Dialog Open',
    props<{ value: boolean }>());

export const protocolNerpCropZoneLevelUpdateLoad = createAction('[Nerp Organization Crop Zones] Update Level Load',
    props<{ input: PutCropZoneLevelInput }>());

export const protocolNerpCropZoneLevelUpdateSuccess = createAction('[Nerp Organization Crop Zones] Update Level Success',
    props<{ result: any }>());

export const protocolNerpCropZoneLevelUpdateFail = createAction('[Nerp Organization Crop Zones] Update Level Fail',
    props<{ errors: any }>());

export const protocolNerpAddProjectLoad = createAction('[Nerp Organization] Add Project Load',
    props<{ input: AddOrganizationProjectInput }>());

export const protocolNerpAddProjectSuccess = createAction('[Nerp Organization] Add Project Success',
    props<{ result: any }>());

export const protocolNerpAddProjectFail = createAction('[Nerp Organization] Add Project Fail',
    props<{ errors: any }>());

export const protocolNerpCropYearsLoad = createAction('[Nerp Organization] Crop Years Load',
    props<{ input: GetCropzoneYearsQueryVariables }>());

export const protocolNerpCropYearsSuccess = createAction('[Nerp Organization] Crop Years Load Success',
    props<{ result: GetCropzoneYearsQuery }>());

export const protocolNerpCropYearsFail = createAction('[Nerp Organization] Crop Years Load Fail',
    props<{ errors: any }>());

export const organizationSelectedProjectChanged = createAction('[Nerp Organization] Change Selected Project', props<{ input: SelectedProjectItem }>());

export const organizationCropYearSelectionChanged = createAction('[Document Manager Organization] Change Crop Year selection',
    props<{ input: { id: string, name: string } | null }>());

export const cleanNerpOrganizationState = createAction('[Nerp Organization] Clean');