import { Component, EventEmitter, Inject, OnDestroy, OnInit } from '@angular/core';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { combineLatestWith, first, Observable, switchMap, takeUntil } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { State } from 'src/app/state/app.state';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { DocumentsInput, DocumentTypeItem, ProjectFileInput } from 'src/app/@core/generated/operations-core-graphql';
import { HttpClient, HttpErrorResponse, HttpEventType, HttpHeaders } from '@angular/common/http';
import { TIdService } from 'src/app/@auth/services/tId.service';
import { handleApolloTemplateError } from 'src/app/@core/utils';
import { ToastrService } from 'ngx-toastr';
import { ContentService } from 'src/app/@core/services/content.service';
import { FileUploadResult } from 'src/app/@core/components/document-manager-shell/upload-file-shell/upload-file.shell.component';
import { catchError, map } from 'rxjs/operators';
import { TemplateResponse } from 'src/app/models/notifications/TemplateResponse';
import { projectDocumentManagerActions, projectDocumentManagerSelectors } from '..';


@Component({
    selector: 'app-edit-document-shell',
    templateUrl: './edit-document-shell.component.html',
    styleUrls: ['./edit-document-shell.component.scss']
})
export class ProjectEditDocumentShellComponent implements OnInit, OnDestroy {

    private onDestroy$ = new EventEmitter<void>();
    isEditDocumentInProgress = this.store.selectSignal(projectDocumentManagerSelectors.selectIsEditDocumentWorking);
    isUploadInProgress = false;

    uploadedFileName!: string;
    documentTypesList !: DocumentTypeItem[]

    formGroup!: FormGroup;
    documentId!: string;
    fileData!: { blobIdentifier: string, blobInfo: string, size: number | null, oldBlobIdentifier: string };
    formData!: { key: string, value: string }[];

    organizationId: string;
    protocolId: string;
    projectId: string;
    url: string = `/api/v1/upload/document`;
    validationErrors$?: Observable<{ message: string }[] | null>;

    constructor(
        private dialogRef: DialogRef<any>,
        private httpClient: HttpClient,
        @Inject(DIALOG_DATA) private data: any,
        private store: Store<State>,
        private tidService: TIdService,
        private toast: ToastrService,
        private contentSrv: ContentService,
        private fb: FormBuilder) {

        dialogRef.disableClose = true;

        this.organizationId = data.organizationId;
        this.protocolId = data.protocolId;
        this.projectId = data.projectId;

        this.formData = [
            {
                key: 'fileUploadData', value: JSON.stringify({
                    organizationId: this.organizationId,
                    protocolId: this.protocolId,
                    projectId: this.projectId
                })
            }
        ];

    }

    ngOnInit(): void {

     
        this.store.pipe(
            takeUntil(this.onDestroy$),
            select(projectDocumentManagerSelectors.selectDocumentSelected),
        ).subscribe((doc) => {

            const document = {
                id: doc?.projectFile.id ?? '',
                fileName: doc?.projectFile.blobIdentifier.split('\\')[2] ?? '',
              
                blobIdentifier: doc?.projectFile.blobIdentifier ?? '',
                blobInfo: doc?.projectFile.blobInfo ?? '',
                size: doc?.projectFile.size ?? 0
            };

           
            this.documentId = document.id;

            this.fileData = {
                blobIdentifier: document.blobIdentifier,
                blobInfo: document.blobInfo,
                size: document.size,
                oldBlobIdentifier: ''
            };

            this.formGroup = this.fb.group({
                currentFile: [document.fileName, Validators.required],
       
                documentName: [document.fileName, Validators.required],
            });
        }
        );

        this.store.select(projectDocumentManagerSelectors.selectEditDocumentDialogOpen).pipe(
            takeUntil(this.onDestroy$)
        ).subscribe(open => {
            if (!open) {
                this.dialogRef.close();
            }
        });
    }

    EditDocument() {
        const docType = this.formGroup.controls['documentType'];
        const docName = this.formGroup.controls['documentName'];

        const documentInput: ProjectFileInput = {
            name: docName.value ?? '',
            blobIdentifier: this.fileData.blobIdentifier,
            blobInfo: this.fileData.blobInfo,
            size: this.fileData.size,
            oldBlobIdentifier: this.fileData.oldBlobIdentifier
        }

        this.store.dispatch(projectDocumentManagerActions.protocolEditDocumentLoad({
            input: {
                protocolId: this.protocolId,
                projectId: this.projectId,
                newFile: documentInput,
                updatedFileId: this.documentId
            }
        }));

    }

    dialogClose() {
        this.store.dispatch(projectDocumentManagerActions.protocolEditDocumentDialogOpen({ value: false }));
    }

    ngOnDestroy(): void {
        this.onDestroy$.emit();
        this.onDestroy$.complete();
    }

    onFileInput(event: any) {

        const document = event.target.files[0];

        const formData = new FormData();

        this.formData.forEach((e) => {
            formData.append(e.key, new Blob([e.value], { type: 'application/json' }));
        });

        formData.append('file', document, document.name);

        this.isUploadInProgress = true;

        this.tidService.tokenStream().pipe(
            first(),
            switchMap(t => {
                const headers = new HttpHeaders({
                    Authorization: `Bearer ${t?.tokens?.token || ''}`
                });
                return this.httpClient.post(this.url, formData, {
                    headers: headers
                })
            }),
            catchError((error: HttpErrorResponse) => {
                this.isUploadInProgress = false;
                handleApolloTemplateError(error, this.toast, this.contentSrv);
                return [];
            })
        ).subscribe(
            (response) => {
                this.isUploadInProgress = false;
                const fileResult = response as FileUploadResult;
                const newDocument = fileResult.files ? fileResult.files[0] : undefined;

                this.uploadedFileName = newDocument?.name ?? '';

                this.fileData =
                {
                    blobIdentifier: newDocument?.identifier ?? '',
                    blobInfo: newDocument?.blobInfo ?? '',
                    size: newDocument?.size ?? 0,
                    oldBlobIdentifier: newDocument?.oldIdentifier ?? ''
                };
            }
        ), {
            error: (error: HttpErrorResponse) => {
                this.isUploadInProgress = false;
                handleApolloTemplateError(error, this.toast, this.contentSrv);
            }
        };
    }



}
