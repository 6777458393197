import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { first, from, Observable, switchMap, takeUntil } from 'rxjs';

import { Store } from '@ngrx/store';
import { State } from 'src/app/state/app.state';
import { size, TranslocoService } from '@jsverse/transloco';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { HttpClient, HttpEventType, HttpHeaders } from '@angular/common/http';
import { TIdService } from 'src/app/@auth/services/tId.service';
import { ToastrService } from 'ngx-toastr';
import { ContentService } from 'src/app/@core/services/content.service';
import * as utils from 'src/app/@core/utils';
import { DocumentTypeFile } from 'src/app/modules/protocol-nerp/models';
import { DocumentsInput, ProjectFileInput } from 'src/app/@core/generated/operations-core-graphql';
import { projectDocumentManagerActions, projectDocumentManagerSelectors } from '..';

@Component({
    selector: 'app-upload-file-shell',
    templateUrl: './upload-file-shell.component.html',
    styleUrls: ['./upload-file-shell.component.scss']
})
export class ProjectUploadFileShellComponent implements OnInit, OnDestroy {
    protocolId: string;
    projectId: string;
    private onDestroy$ = new EventEmitter<void>();
    isWorking = this.store.selectSignal(projectDocumentManagerSelectors.selectDocumentUploadIsWaiting);
    uploadFilesGroup = new FormGroup({
        files: new FormArray<FormGroup<FileFormArrayItem>>([]),
    });
    showMessage: boolean = false;
    @ViewChild('fileUpload') fileUpload: any;
    private filesRemovingFromDropZone = 0;
    url: string = `/api/v1/upload/document`;
    formData!: { key: string, value: string }[];
    alertMessage: string = '';
    validationErrors$?: Observable<{ name: string; parameters: any }[] | null>;
    submitFileForm = new FormGroup({
        blobIdentifier: new FormControl<string | null>(null, Validators.required),
        blobInfo: new FormControl<string | null>(null, Validators.required),
        name: new FormControl<string | null>(null, Validators.required),
        downloadUrl: new FormControl<string | null>(null, Validators.required),
        year: new FormControl<string | null>(null, [Validators.required]),
        size: new FormControl<number | null>(null, [Validators.required]),
    });


    constructor(private httpClient: HttpClient,
        private langSvc: TranslocoService,
        private dialogRef: DialogRef<uploadFileDialogResult>,
        private tidService: TIdService,
        @Inject(DIALOG_DATA) private data: any,
        private store: Store<State>,
        private toast: ToastrService,
        private contentSrv: ContentService) {

        dialogRef.disableClose = true;
        this.protocolId = data.protocolId;
        this.projectId = data.projectId;

        this.uploadFilesGroup.controls.files.setValue([]);
        this.formData = [
            {
                key: 'fileUploadData', value: JSON.stringify({
                    protocolId: this.protocolId,
                    projectId: this.projectId
                })
            }
        ];
    }

    ngOnInit(): void {
        this.store.select(projectDocumentManagerSelectors.selectProtocolFileUploadDialogOpen).pipe(
            takeUntil(this.onDestroy$)
        ).subscribe(open => {
            if (!open) {
                this.dialogRef.close();
            }
        });
    }


    dialogClose() {
        this.store.dispatch(projectDocumentManagerActions.protocolProjectFileUploadDialogOpen({ value: false }));
    }

    ngOnDestroy(): void {
        this.onDestroy$.emit();
        this.onDestroy$.complete();
    }



    SubmitFile() {
        const formValues = this.submitFileForm.value;

        const documentInputs: ProjectFileInput[] = [{
            blobIdentifier: formValues.blobIdentifier ? formValues.blobIdentifier : '',
            blobInfo: formValues.blobInfo ? formValues.blobInfo : '',
            name: formValues.name ? formValues.name : '',
            size: formValues.size,
        }];

        this.store.dispatch(projectDocumentManagerActions.protocolSubmitFilesUploadedLoad({
            input: {
                protocolId: this.protocolId,
                projectId: this.projectId,
                documents: documentInputs
            }
        }));

    }

    onFileUploadResponse($event: any) {
        
        const file: {
            name: string,
            rows: string[][],
            identifier: string,
            oldIdentifier: string,
            blobInfo: string,
            size: number,
            downloadUrl: string
        } = $event.files[0];

        this.submitFileForm.setValue({
            blobIdentifier: file.identifier,
            // columnMapping: null,
            year: null,
            blobInfo: file.blobInfo,
            name: file.name,
            size: file.size,
            downloadUrl: file.downloadUrl
        });
    }



    onCloseMessage(e: Event): void {
        this.showMessage = false;
    }

    protected readonly Object = Object;
}

export interface uploadFileDialogResult {
    idDocument: string;
    documentName: string;
}

export interface FileUploadResult {
    organizationId: string;
    protocolId: string;
    projectId: string;
    files?: FileUploadFileResult[];
    count: number;
    size: number;
}

export interface FileUploadFileResult {
    name: string;
    identifier: string;
    blobInfo: string;
    size: number;
    oldIdentifier: string;
}

export interface FileFormArrayItem {
    iconType: FormControl<string | null>,
    name: FormControl<string | null>,
    nameFile: FormControl<string | null>,
    loaded: FormControl<number | null>,
    size: FormControl<number | null>,
    category: FormControl<DocumentTypeFile | null | undefined>
    statusbar: FormControl<number | null>,
    blobIdentifier: FormControl<string | null>,
    blobInfo: FormControl<string | null>,
    fileType: FormControl<string | null>,
}
