import { Component, OnDestroy, OnInit, Optional, Host, Input, Output, EventEmitter } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { projectDocumentManagerActions, projectDocumentManagerSelectors } from './index';
import { combineLatestWith, debounceTime, filter, map, Observable, of, Subject, takeUntil } from 'rxjs';
import { GridOptions, IRowNode } from 'ag-grid-community';
import { State } from 'src/app/state/app.state';
import { BinaryFilterOperation } from '../../generated/operations-core-graphql';
import { DialogService } from '../dialog/dialog.service';
import { CdkScrollable } from '@angular/cdk/overlay';
import { ContentService } from '../../services/content.service';
import { ToastrService } from 'ngx-toastr';
import { LinkToProjectCellEditor } from '../ccx-ag-datagrid/cellEditor/link-to-project-cell-editor.component';
import { GridColumnConfigurationAg } from '../ccx-grid/ccx-grid.component';
import { documentStatusCellEditor } from '../ccx-ag-datagrid/cellEditor/document-status-cell-editor.component';
import { DataSource } from 'src/app/@core/components/data-source';
import { data } from 'src/app/Components/data-table/DataTableOptions';
import { localizeGrid } from '../../utils';
import { TranslocoService } from '@jsverse/transloco';
import { ProjectChangeLogModalComponent } from './change-log-modal/change-log-modal.component';
import { ProjectEditDocumentShellComponent } from './project-edit-document-shell/edit-document-shell.component';
import { ProjectUploadFileShellComponent } from './project-upload-file-shell/upload-file.shell.component';
import { ProjectFileRow } from './+state/project-document-manager.selectors';
import { ActivatedRoute } from '@angular/router';

export class SelectedProjectItem {
    id!: string;
    name!: string;
    year!: number;
    status!: string;
}

@Component({
    selector: 'project-document-manager-shell',
    templateUrl: './project-document-manager.shell.component.html',
    styleUrls: ['./project-document-manager.shell.component.scss']
})
export class ProjectDocumentManagerShellComponent implements OnInit, OnDestroy {
    isWorking$!: Observable<boolean>
    filterCounter: number = 0;
    // projectId!: string;
    isWorking = this.store.selectSignal(projectDocumentManagerSelectors.selectIsWorking);
    gridOptions: GridOptions<any>;
    private onDestroy$ = new Subject<void>();
    searchByInput = new Subject<string>();
    rowsSelected!: any;
    canDelete = false;

    @Input() protocolId!: string | undefined;
    @Input() selectedProject: any | undefined;
    @Input() isQualityControlUser = false;

    @Output() cellLinkClicked = new EventEmitter<any>();
    @Output() onToggleFilter = new EventEmitter();


    constructor(
        private store: Store<State>,
        readonly dialogService: DialogService,
        private langSrc: TranslocoService,
        private contSrvc: ContentService,
        private toastr: ToastrService,
        private route: ActivatedRoute,
        @Optional() @Host() scrollContainer: CdkScrollable
    ) {
        this.gridOptions = {
            context: {
                componentParent: this,
                parentElementRef: scrollContainer
            }
        };
    }

    selectedUtilitySection = '';
    @Input() ProjectDocumentDataSource!: DataSource<ProjectFileRow>;

    ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    ngOnInit(): void {

        this.route.params.pipe(takeUntil(this.onDestroy$),)
        .subscribe((params) => {
            this.protocolId = params['protocolId'];
        });

        this.store.select(projectDocumentManagerSelectors.selectProtocolFileUploadDialogOpen)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((open) => {
                if (open) {
                    this.dialogService.create(ProjectUploadFileShellComponent, {
                        protocolId: this.protocolId,
                        projectId: this.selectedProject.projectId
                    });
                }
            });


        this.store.pipe(
            takeUntil(this.onDestroy$),
            select(projectDocumentManagerSelectors.selectEditDocumentDialogOpen),)
            .subscribe((open) => {
                if (open) {
                    this.dialogService.create(ProjectEditDocumentShellComponent, {
                        protocolId: this.protocolId,
                        projectId: this.selectedProject.projectId
                    });
                }
            });

        this.store.pipe(
            takeUntil(this.onDestroy$),
            select(projectDocumentManagerSelectors.selectProjectChangeLogModalDialogState),
            combineLatestWith(this.store.select(projectDocumentManagerSelectors.selectChangeLogModalDocumentId))
        )
            .subscribe(([open, docId]) => {
                if (open && docId) {
                    this.dialogService.create(ProjectChangeLogModalComponent, {
                        documentId: docId
                    });
                }
            });

        this.isWorking$ = this.store.select(projectDocumentManagerSelectors.selectIsWorking);
        this.ProjectDocumentDataSource.dataResponse$ = this.store.pipe(
            select(projectDocumentManagerSelectors.getProjectDocumentGrid),
            map(item =>
                this.selectedProject.status == "Locked" ? {
                    ...item,
                    data: localizeGrid(
                        this.ProjectDocumentDataSource.gridColumnConfigurations,
                        item.data.map(e => ({ ...e, status: "Locked" })),
                        this.contSrvc,
                        this.langSrc
                    ),
                } : item
            )
        );

        this.ProjectDocumentDataSource.onDataRequest$
            .pipe()
            .subscribe((e) => {
                this.store.dispatch(
                    projectDocumentManagerActions.protocolProjectDocumentLoad({
                        input: {

                            protocolId: this.protocolId,
                            projectId: this.selectedProject.projectId,
                            ...e
                        }
                    })
                );

            });


        this.store.select(projectDocumentManagerSelectors.selectProjectDocumentList)
            .pipe(takeUntil(this.onDestroy$),
                filter(e => {
                    return e == null;
                })
            ).subscribe(e => {
                this.ProjectDocumentDataSource.onPageChange(0);
            });


        this.searchByInput.pipe(
            takeUntil(this.onDestroy$),
            debounceTime(500)
        ).subscribe(e => {
            this.ProjectDocumentDataSource.gridSearchBy({
                exprGrp: [{
                    grpOp: BinaryFilterOperation.And,
                    expr: {
                        column: 'name',
                        operator: 'contains',
                        value: e
                    }
                }]
            });
        });



    }

    gridSearchBy(evt: CustomEvent<string>) {
        this.searchByInput.next(evt.detail);
    }

    getSelectedRows(grid: any) {
        this.rowsSelected = grid.api.getSelectedRows();
        this.canDelete = this.rowsSelected.length != 0;
    }

    onSelectionChanged(grid: any) {
        this.getSelectedRows(grid);
    }
    onModelUpdated(grid: any) {
        this.getSelectedRows(grid);
    }

    filterToggled() {
        console.log('uy');
        this.onToggleFilter.emit();
    }

    onClickUpload() {
        this.fileUploadPopup();
    }

    onCellLinkClick($event: IRowNode<ProjectFileRow>) {
        this.cellLinkClicked.emit($event);
    }

    fileUploadPopup() {
        if (this.protocolId && this.selectedProject.projectId) {
            this.store.dispatch(projectDocumentManagerActions.protocolProjectFileUploadDialogOpen({ value: true }));
        } else {
            this.toastr.info(this.langSrc.translate('First select a project to upload files'));
        }
    }
}

export const projectDocumentGridConfiguration: GridColumnConfigurationAg[] = [
    {
        field: 'id',
        headerName: 'id',
        hide: true,
        columnSourceId: 'id',
        id: 'id',
        type: 'leftAligned',
        filterType: 'none',
        typeValue: 'string'
    },
    {
        field: 'blobIdentifier',
        headerName: 'blobIdentifier',
        hide: true,
        columnSourceId: 'blobIdentifier',
        id: 'blobIdentifier',
        type: 'leftAligned',
        filterType: 'none',
        typeValue: 'string'
    },
    {
        field: 'documentId',
        headerName: 'documentId',
        hide: true,
        columnSourceId: 'documentId',
        id: 'documentId',
        type: 'leftAligned',
        filterType: 'none',
        typeValue: 'string'
    },

    {
        field: 'name',
        headerName: 'Name',
        columnSourceId: 'name',
        id: 'name',
        filterType: 'text',
        typeValue: 'string',
        minWidth: 250,
        maxWidth: 250,
        wrapText: true,
        autoHeight: true,
        cellStyle: { 'line-height': '1.8em', 'padding': '0.5em 0px', 'word-break': 'break-all' },

    },
    {
        field: 'fileName',
        headerName: 'File Name',
        columnSourceId: 'fileName',
        id: 'fileName',
        filterType: 'text',
        typeValue: 'string',
        cellRenderer: LinkToProjectCellEditor,
        minWidth: 200,
        wrapText: true,
        autoHeight: true,
        cellStyle: { 'line-height': '1.8em', 'padding': '0.5em 0px', 'word-break': 'break-all' },

    },
    {
        field: 'size',
        headerName: 'Size',
        columnSourceId: 'size',
        id: 'size',
        filterType: 'text',
        typeValue: 'string',
        maxWidth: 200,
    }
];
